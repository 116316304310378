export default function LogoNewChat(props: any) {
  return (
    <div className="flex gap-4 items-center justify-center">
      <div
        className={`max-w-5xl w-full mx-auto flex gap-4 items-center justify-center ${
          props.hasMessages
            ? "py-6 max-md:bg-white max-md:fixed max-md:top-0"
            : ""
        }`}
      >
        <img
          src={
            props.hasMessages
              ? "../../images/logo-H.svg"
              : "../../images/logo-V.svg"
          }
          alt="logo"
          className={props.hasMessages ? "max-md:h-6" : ""}
        />
      </div>
    </div>
  );
}
