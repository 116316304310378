import React, { useState } from "react";
import { formatTimeAgo } from "../utils/dateUtils";

interface ChatMessageProps {
  isAssistant?: boolean;
  content: string;
  userName?: string;
  timestamp?: number;
  id?: number;
}

export const ChatMessage: React.FC<ChatMessageProps> = ({
  isAssistant = false,
  content,
  userName,
  timestamp,
  id,
}) => {
  const UN = () => {
    if (userName && userName.trim() !== "") {
      const words = userName.split(" ").filter(Boolean); // Split sentence into words and remove extra spaces
      if (words.length > 1) {
        const firstLetterFirstWord = words[0][0]; // First letter of the first word
        const firstLetterLastWord = words[words.length - 1][0]; // First letter of the last word
        return firstLetterFirstWord + firstLetterLastWord; // Concatenate results
      } else if (words.length === 1) {
        return words[0][0]; // Handle case with only one word
      }
    }
    return ""; // Handle empty or undefined input
  };
  const userNAmeText = UN();
  return (
    <div
      className={`flex gap-3 rounded-tr-2xl rounded-bl-2xl text-gray-950 px-4 scroll-mt-8`}
      id={`${id}`}
    >
      {!isAssistant ? (
        <div className="min-w-6 min-h-6 w-6 h-6 md:min-w-10 md:min-h-10 md:h-10 text-xs md:text-base flex justify-center items-center text-primary-950 font-bold rounded-full bg-primary-200">
          {userNAmeText}
        </div>
      ) : (
        <div className="shrink-0 flex items-center justify-center min-w-6 min-h-6 w-6 h-6 md:min-w-10 md:min-h-10 md:h-10 rounded-full bg-white p-1 md:p-2">
          <img alt="logo" src="../../images/Favicon.svg" />
        </div>
      )}
      <div
        className={`flex-1 flex flex-col gap-0 rounded-tr-2xl rounded-bl-2xl text-gray-950  ${
          isAssistant
            ? "bg-white p-3 md:p-5  border border-primary-100 items-start hover:shadow-lg hover:shadow-primary-100"
            : "items-center "
        }`}
      >
        <div className="flex-1 w-full">
          <pre className="text-wrap font-sans">{content}</pre>
        </div>
        {timestamp && !isAssistant && (
          <div className="text-xs text-gray-500 self-start">
            {formatTimeAgo(timestamp)}
          </div>
        )}
      </div>
    </div>
  );
};
