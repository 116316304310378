import { useOktaAuth } from '@okta/okta-react';
import React, { useEffect } from 'react';

interface SecureRouteProps {
  children: React.ReactNode;
}

const SecureRoute: React.FC<SecureRouteProps> = ({ children }) => {
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if (authState && !authState.isAuthenticated) {
      oktaAuth.signInWithRedirect();
    }
  }, [oktaAuth, authState]);

  if (!authState || !authState.isAuthenticated) {
    return <div>Redirecting to login...</div>;
  }

  return <>{children}</>;
};

export default SecureRoute;
