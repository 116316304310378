import { OktaAuth } from '@okta/okta-auth-js';


if (!process.env.REACT_APP_OKTA_ISSUER || !process.env.REACT_APP_OKTA_CLIENT_ID || !process.env.REACT_APP_OKTA_REDIRECT_URI) {
  throw new Error('Required Okta environment variables are missing');
}

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_ISSUER as string,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID as string,
  redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI as string,
  scopes: ['openid', 'profile', 'email', 'offline_access'],
  pkce: true,
  tokenManager: {
    autoRenew: true,
    autoRemove: true
  }
});

export default oktaAuth;
