import {
  ArrowRightStartOnRectangleIcon,
  Bars3Icon,
  ChatBubbleLeftEllipsisIcon,
  ChatBubbleLeftRightIcon,
  CheckCircleIcon,
  QuestionMarkCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { memo, useState } from "react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";

interface ChatListItemProps {
  chat: any;
  onChatClick: (chat: any) => void;
  onDeleteChat: (chatId: string) => void;
  currentTopicId?: string | null;
  showRecentChatConfirm: any;
}

const ChatListItem = memo(
  ({
    chat,
    onChatClick,
    onDeleteChat,
    currentTopicId,
    showRecentChatConfirm,
  }: ChatListItemProps) => {
    const messageCount = chat.conversations?.length || 0;
    const tooltipText = `${chat.title}\n(${messageCount} message${
      messageCount !== 1 ? "s" : ""
    })`;
    return (
      <li key={chat.id} title={tooltipText}>
        <div className="group relative flex items-center gap-2">
          <button
            onClick={() => onChatClick({ ...chat })}
            className="flex-wrap flex-1 group-hover:bg-white focus:bg-white hover:text-primary-950 flex items-center gap-2 rounded-md px-2 py-1.5 transition-colors duration-300 text-[#171717] dark:text-gray-400 dark:group-hover:text-gray-200 dark:group-hover:bg-primary-800 text-sm cursor-pointer"
          >
            <ChatBubbleLeftEllipsisIcon className="w-4 h-4 shrink-0 group-hover:text-primary-alt-600 dark:group-hover:text-gray-50" />
            <div className="min-w-0 max-w-56 truncate flex-1 text-sm text-left">
              {chat.title}
            </div>
          </button>
          <TrashIcon
            className="group-hover:opacity-100 group-hover:pointer-events-auto pointer-events-none opacity-0 shrink-0 w-3 h-3 hover:text-red-600 dark:text-gray-400 dark:hover:text-red-300 cursor-pointer"
            onClick={() => showRecentChatConfirm(chat.id)}
          />
        </div>
      </li>
    );
  }
);

interface SidebarProps {
  isPinned: boolean;
  showRecentChatConfirm: any;
  setIsPinned: (value: boolean) => void;
  data: any[];
  handleLogout: () => void;
  onChatClick: (chat: any) => void;
  onDeleteChat: (chatId: string) => void;
  currentTopicId?: string | null;
  setShowHistory: (value: boolean) => void;
  handleNewChat: any;
}

const Sidebar = memo((props: SidebarProps) => {
  return (
    <div
      className={`z-30 pb-4  flex flex-col transition-all duration-300 ease-in-out -translate-x-full md:-translate-x-0 w-full  bg-white md:bg-transparent max-md:fixed max-md:bottom-0 max-md:top-0 max-md:right-0 max-md:left-0 max-md:z-20 ${
        props.isPinned
          ? "md:w-80 max-md:translate-x-0"
          : "md:w-20 max-md:-translate-x-full"
      }`}
    >
      <div className="flex items-center flex-col p-4">
        <button
          onClick={() => {
            props.setIsPinned(!props.isPinned);
          }}
          className={`group relative transition-all duration-300 ease-in-out  md:relative md:z-0  max-md:fixed max-md:-right-12 max-md:top-6 ${
            props.isPinned
              ? "ml-auto max-md:left-4"
              : "mx-auto  md:-right-0 z-20"
          }`}
        >
          <span
            className={`group-hover:opacity-100 group-hover:pointer-events-auto pointer-events-none text-nowrap opacity-0 transition-opacity bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute mx-auto left-full translate-x-2`}
          >
            Show/Collapse
          </span>
          <Bars3Icon className="h-6 w-6 text-gray-600 hover:text-primary-alt-600 dark:text-gray-400 dark:hover:text-gray-200" />
        </button>
      </div>
      <div className="px-4 max-md:mt-12">
        <button
          onClick={props.handleNewChat}
          className="group relative hover:bg-primary-alt-600 hover:text-white group flex items-center gap-2 rounded-md px-3 py-2 mb-4 transition-colors duration-300 border border-primary-alt-600 text-primary-alt-600 dark:text-primary-400 dark:border-primary-700 dark:hover:bg-primary-700 w-full justify-center"
        >
          <ChatBubbleLeftRightIcon className="w-4 h-4" />
          {props.isPinned ? (
            <span className="inline-block text-sm">New Chat</span>
          ) : (
            <span
              className={`group-hover:opacity-100 group-hover:pointer-events-auto pointer-events-none text-nowrap opacity-0 transition-opacity bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute mx-auto ${
                props.isPinned
                  ? "left-1/2 -translate-x-1/2 -top-8"
                  : "left-full translate-x-2 top-1/2 -translate-y-1/2"
              }`}
            >
              New Chat
            </span>
          )}
        </button>
      </div>
      <div
        className={`flex-1 overflow-y-auto transition-opacity duration-300 ease-in-out md:group-hover:opacity-100 `}
      >
        <div className="px-4 flex-1">
          <div
            className={`space-y-1  ${
              props.isPinned ? "md:opacity-100" : "md:opacity-0 hidden "
            }`}
          >
            <h6 className="text-xs font-medium text-gray-400 pl-2">RECENTS</h6>

            {props.data.length === 0 ? (
              <div className="text-center text-gray-500 py-4">
                No chat history
              </div>
            ) : (
              <>
                <ul className="space-y-1">
                  {props.data.map((chat: any) => (
                    <ChatListItem
                      key={chat.id}
                      chat={chat}
                      onChatClick={props.onChatClick}
                      onDeleteChat={props.onDeleteChat}
                      currentTopicId={props.currentTopicId}
                      showRecentChatConfirm={props.showRecentChatConfirm}
                    />
                  ))}
                </ul>
                <div className="text-right !mt-4">
                  <button
                    onClick={() => {
                      props.setShowHistory(true);
                      if (window.innerWidth < 767) {
                        props.setIsPinned(false);
                      }
                    }}
                    className="hover:text-primary-alt-600 cursor-pointer -mx-1.5 inline-flex items-center gap-1 rounded-md px-1.5 py-1 transition-colors duration-300 text-[#6C6C6C] text-xs dark:text-gray-400 dark:hover:text-primary-alt-400"
                  >
                    <span>View All</span>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div
        className={`flex justify-center items-center text-center  gap-1 ${
          props.isPinned ? "flex-row" : "flex-col"
        }`}
      >
        <a
          href="https://beyondid.atlassian.net/wiki/spaces/BIDAIAW/pages/3288563713/BeyondID+AI+Work+General+Help+Page"
          target="_blank"
          className="group relative text-gray-700 hover:text-primary-alt-800 hover:bg-primary-alt-200 p-2 rounded-full transition-colors duration-300 cursor-pointer dark:text-gray-400 "
        >
          <QuestionMarkCircleIcon className="w-6 h-6" />
          <span
            className={`group-hover:opacity-100 group-hover:pointer-events-auto pointer-events-none opacity-0 transition-opacity bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute mx-auto ${
              props.isPinned
                ? "left-1/2 -translate-x-1/2 -top-8"
                : "left-full translate-x-2 top-1/2 -translate-y-1/2"
            }`}
          >
            Help
          </span>
        </a>

        <button
          title="Logout"
          onClick={props.handleLogout}
          className="group relative text-gray-700 hover:text-primary-alt-800 hover:bg-primary-alt-200 p-2 rounded-full transition-colors duration-300 cursor-pointer dark:text-gray-400 "
        >
          <ArrowRightStartOnRectangleIcon className="w-6 h-6" />
          <span
            className={`group-hover:opacity-100 group-hover:pointer-events-auto pointer-events-none opacity-0 transition-opacity bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute mx-auto ${
              props.isPinned
                ? "left-1/2 -translate-x-1/2 -top-8"
                : "left-full translate-x-2 top-1/2 -translate-y-1/2"
            }`}
          >
            Logout
          </span>
        </button>
      </div>
    </div>
  );
});

export default Sidebar;

const AnswerType = (props: any) => {
  const settingValue_new = (token: any, tempature: any, temp: number) => {
    const tokenValue = (tempature * token).toFixed();
    props.setSettingsValues({ max_token: tokenValue, temperature: temp });
  };
  return (
    <label
      onClick={() => settingValue_new(props.token, props.tempature, props.temp)}
      className="peer-checked:bg-primary-400 bg-primary-100 rounded py-2 px-4  cursor-pointer flex items-center  justify-between transition-opacity duration-300"
    >
      <input
        type="radio"
        id="setting_normal"
        name="settings"
        value="Normal"
        className="hidden peer"
      />

      <span className="block peer-checked:text-primary-alt-600">
        <h4 className="font-bold mb-0 leading-tight ">{props.title}</h4>{" "}
        <span className="text-xs text-gray-500">{props.desc}</span>
      </span>
      <CheckCircleIcon className="opacity-0 peer-checked:opacity-100 h-5 w-5 text-primary-alt-600" />
    </label>
  );
};
