/*
    Currently, the max settings are per session
    These are stored in local storage
*/

const attachmentConfig = {
    MAX_ATTACHMENTS: 5,
    MAX_SINGLE_FILE_SIZE: 1024 * 1024 * 1, // 1MB
    MAX_TOTAL_FILE_SIZE: 1024 * 1024 * 10, // 10MB
    ALLOWED_FILE_EXTENSIONS: ['.pdf', '.xls', '.doc', '.docx', '.xlsx', '.png', '.jpg', '.jpeg', '.txt'],
    ALLOWED_MIME_TYPES: ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/png', 'image/jpeg', 'text/plain'],
  };
  
  export default attachmentConfig;