import {
  ChatBubbleLeftEllipsisIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useUserHistory } from "../hooks/useUserHistory";
import { formatTimeAgo } from "../utils/dateUtils";
import ResponseLoader from "./ResponseLoader";
interface HistoryProps {
  onChatClick: (chat: any) => void;
  onDeleteChat: (chatId: string) => void;
  currentTopicId?: string | null;
}
export default function AllHistory(props: any) {
  const { history } = useUserHistory();
  return (
    <div className="h-full p-8">
      <div className="flex justify-between">
        <h2 className="text-2xl font-semibold mb-2 max-md:pl-8">
          Chat History
        </h2>
      </div>
      {history ? (
        history.map((item: any) => {
          return (
            <div
              key={item.id}
              className="group min-w-0 w-full flex gap-4 justify-between items-center relative group p-3 rounded-lg cursor-pointer bg-white mb-3 hover:bg-primary-alt-100 "
            >
              <button
                onClick={() => props.onChatClick(item)}
                className="min-w-0 w-full flex gap-4 items-center"
              >
                <ChatBubbleLeftEllipsisIcon className="w-10 h-10 p-2 rounded-full group-hover:bg-primary-alt-50 group-hover:text-primary-alt-600 bg-gray-100 text-gray-400" />
                <div className="min-w-0 flex flex-col gap-1 items-start">
                  <div className="truncate text-gray-900 transition-all text-lg font-normal">
                    {item.title}
                  </div>
                  <div className="flex gap-2 items-center">
                    <div className="text-xs text-gray-500 self-start">
                      <span className="font-bold text-gray-950">
                        {item.conversations.length}
                      </span>{" "}
                      Conversations
                    </div>
                    <div className="w-1 h-1 rounded-full bg-gray-300"></div>
                    <div className="text-xs text-gray-500 self-start">
                      {formatTimeAgo(new Date(item.updated_at).getTime())}
                    </div>
                  </div>
                </div>
              </button>
              <TrashIcon
                onClick={(e) => {
                  props.showRecentChatConfirm(item.id);
                }}
                className="group-hover:opacity-100 group-hover:pointer-events-auto opacity-0 pointer-events-none w-4 h-4 hover:text-red-600 cursor-pointer"
              />
            </div>
          );
        })
      ) : (
        <ResponseLoader />
      )}
    </div>
  );
}
