import React, { useState, useRef, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { ChatMessage } from "./ChatMessage";
import { useUserHistory } from "../hooks/useUserHistory";
import Sidebar from "./Sidebar";
import LogoNewChat from "./LogoNewChat";
import Greeting from "./Greeting";
import { ChatBubbleBottomCenterTextIcon } from "@heroicons/react/24/outline";
import appConfig from "../config/appConfig";
import PromptInput from "./PromptInput";
import ResponseLoader from "./ResponseLoader";
import {
  Description,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import AllHistory from "./AllHistory";
import { Link } from "react-router-dom";

interface Message {
  role: "user" | "assistant";
  content: string;
  timestamp: number;
  id: number;
}

interface Citation {
  generatedResponsePart: {
    textResponsePart: {
      text: string;
      span: {
        start: number;
        end: number;
      };
    };
  };
}

interface Conversation {
  citations: Citation[];
  created_at: string;
  prompt: string;
  bot_response: string;
  id: number;
}

interface Topic {
  id: string;
  title: string;
  conversations: Conversation[];
  created_at: string;
  updated_at: string;
}

interface ApiResponse {
  success: boolean;
  message: string;
  error: string | null;
  status_code: number;
  data: {
    id: string;
  } | null;
}

export const Chat: React.FC = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const userId = authState?.idToken?.claims.sub;
  const { history, refreshHistory } = useUserHistory();

  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [currentTopicId, setCurrentTopicId] = useState<string>("");
  const [selectedModel, setSelectedModel] = useState<string>(
    "anthropic.claude-v2:1"
  );
  const [selectedType, setSelectedType] = useState<number>(1);
  const [questionAskedState, setQuestionAskedState] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isPinned, setIsPinned] = useState(false);
  const [activeTab, setActiveTab] = useState<"knowledge" | "general">(
    (localStorage.getItem("activeTab") as "knowledge" | "general") ||
      "knowledge"
  );
  const [isModelDropdownOpen, setIsModelDropdownOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  const [settingsValues, setSettingsValues] = useState({
    max_token: 2048,
    temperature: 0.7,
  });
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const llm = [
    { id: "anthropic.claude-v2:1", name: "Claude V2" },
    { id: "anthropic.claude-v2", name: "Claude V2 (Previous)" },
    { id: "anthropic.claude-instant-v1", name: "Claude Instant" },
  ];

  const chatResponseType = [
    {
      id: 1,
      type: "Normal",
      desc: "Default response from the selected LLM.",
      temp: 1,
      token: 1,
    },
    {
      id: 2,
      type: "Concise",
      desc: "Short response with no elaboration from the LLM..",
      temp: 0,
      token: 0.25,
    },
    {
      id: 3,
      type: "Analytical",
      desc: "Responses will be more concise and less creative.",
      temp: 0.25,
      token: 0.5,
    },
    {
      id: 4,
      type: "Formal",
      desc: "Responses will be more creative.",
      temp: 0.75,
      token: 0.75,
    },
  ];
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
  }, [activeTab]);

  const handleNewChat = () => {
    setShowHistory(false);
    setCurrentTopicId("");
    setMessages([]);
    setInputValue("");
    setSelectedModel("anthropic.claude-v2:1");
    setActiveTab("knowledge");
    localStorage.setItem("activeTab", "knowledge");
    if (window.innerWidth < 767) {
      setIsPinned(false);
    }
  };

  const handleChatClick = (chat: Topic) => {
    // Set the current topic ID
    setShowHistory(false);
    setCurrentTopicId(chat.id);
    if (window.innerWidth < 767) {
      setIsPinned(false);
    }

    // Load existing conversations for this topic
    if (chat.conversations && chat.conversations.length > 0) {
      // Convert topic conversations to chat messages
      const existingMessages: Message[] = chat.conversations.flatMap((conv) => [
        {
          role: "user",
          content: conv.prompt,
          timestamp: conv.created_at
            ? new Date(conv.created_at).getTime()
            : Date.now(),
          id: conv.id,
        },
        {
          role: "assistant",
          content: conv.bot_response,
          timestamp: conv.created_at
            ? new Date(conv.created_at).getTime() + 1
            : Date.now() + 1,
          id: conv.id,
        },
      ]);
      // Set the messages for this topic
      setMessages(existingMessages);
    } else {
      // If no conversations, reset messages
      setMessages([]);
    }

    // Reset input value
    setInputValue("");
  };

  const handleSubmit = async (e: React.FormEvent, files?: File[]) => {
    e.preventDefault();

    if (!inputValue.trim() || !userId || isSubmitting) return;

    const newMessage: Message = {
      role: "user",
      content: inputValue.trim(),
      timestamp: Date.now(), // Use Date.now() to ensure valid timestamp
      id: 0,
    };

    setMessages((prev) => [...prev, newMessage]);
    setInputValue("");
    setIsSubmitting(true);

    try {
      const accessToken = authState?.accessToken?.accessToken;
      if (!accessToken) {
        throw new Error("No access token available");
      }

      // Prepare files for upload
      const filePromises = files
        ? files.map(async (file) => ({
            name: file.name,
            type: file.type,
            size: file.size,
            content: await fileToBase64(file),
          }))
        : [];

      const encodedFiles = await Promise.all(filePromises);

      const payload = {
        user_id: userId,
        topic_id: currentTopicId,
        max_input_tokens: appConfig.maxInputToken,
        max_output_tokens: appConfig.maxOuputToken,
        max_tokens: settingsValues.max_token,
        temperature: settingsValues.temperature,
        prompt: newMessage.content,
        files: encodedFiles,
        model: selectedModel,
        source: activeTab,
      };

      const response = await fetch(appConfig.urls.chat, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const responseData: ApiResponse = await response.json();

        if (!responseData.success) {
          throw new Error(responseData.error || "API request failed");
        }
        if (!responseData?.data?.id) {
          throw new Error("No topic ID in response");
        }

        // Set current topic ID
        setCurrentTopicId(responseData.data.id);

        // Refresh history and get latest data
        const updatedHistory = await refreshHistory();

        // Get the latest conversation from the fresh history data
        const matchingTopic = updatedHistory
          ? updatedHistory.find((topic) => topic.id === responseData?.data?.id)
          : undefined;

        const latestConversation =
          matchingTopic?.conversations?.[
            matchingTopic.conversations.length - 1
          ];
        if (!latestConversation) {
          throw new Error("No conversation found in topic");
        }

        // Get the response text directly from latestConversation
        const responseText = latestConversation.bot_response;
        if (!responseText) {
          throw new Error("No response text found in conversation");
        }

        const assistantMessage: Message = {
          role: "assistant",
          content: responseText,
          timestamp: Date.now(), // Use Date.now() to ensure valid timestamp
          id: 0,
        };

        setMessages((prev) => [...prev, assistantMessage]);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to send message");
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Utility function to convert File to Base64
  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleDeleteTopic = async () => {
    if (!userId) {
      throw new Error("User ID not found");
    }

    try {
      const accessToken = authState?.accessToken?.accessToken;
      if (!accessToken) {
        throw new Error("No access token available");
      }

      const response = await fetch(
        appConfig.urls.deleteTopic(userId, currentTopicId),
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete topic");
      }

      const responseData: ApiResponse = await response.json();

      if (!responseData.success) {
        throw new Error(responseData.error || "Delete topic failed");
      }
      setIsConfirm(false);
      // Clear current topic if deleted topic was the current one
      setCurrentTopicId("");
      setMessages([]);

      // Refresh history after successful deletion
      await refreshHistory();
    } catch (error) {
      console.error("Error deleting topic:", error);
      // Optional: Add user-facing error notification
    }
  };

  const handleLogout = async () => {
    await oktaAuth.signOut();
  };

  if (!authState?.isAuthenticated) {
    return null;
  }

  const showRecentChatConfirm = (chatId: any) => {
    setIsConfirm(true);
    setCurrentTopicId(chatId);
  };

  return (
    <div className="flex h-screen bg-gradient-to-tl from-primary-300 to-white md:py-3 md:pr-3 dark:bg-primary-700 dark:from-primary-950 dark:to-primary-900">
      <Sidebar
        isPinned={isPinned}
        setIsPinned={setIsPinned}
        showRecentChatConfirm={showRecentChatConfirm}
        data={history?.slice(0, 10) || []}
        handleLogout={handleLogout}
        onChatClick={handleChatClick}
        onDeleteChat={handleDeleteTopic}
        currentTopicId={currentTopicId}
        setShowHistory={setShowHistory}
        handleNewChat={handleNewChat}
      />
      <Dialog
        open={isConfirm}
        onClose={() => setIsConfirm(false)}
        className="relative z-50 "
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-black/50 data-[closed]:opacity-0 duration-100"
        />
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <DialogPanel className="max-w-lg space-y-4 border bg-white p-12 rounded-lg">
            <DialogTitle className="font-bold text-2xl text-red-600">
              Remove Chat
            </DialogTitle>
            <Description>
              This will permanently delete the selected chat
            </Description>
            <p>
              Are you sure you want to remove your selected chat history? All of
              your chat will be permanently removed.
            </p>
            <div className="flex gap-4 justify-end !mt-8">
              <button autoFocus onClick={() => setIsConfirm(false)}>
                Cancel
              </button>
              <button
                className="py-3 px-5 bg-primary-alt-600 rounded text-white"
                onClick={() => handleDeleteTopic()}
              >
                Yes, I am sure
              </button>
            </div>
          </DialogPanel>
        </div>
      </Dialog>

      <div
        className={`border border-primary-100 relative flex-1 flex flex-col bg-white bg-opacity-50 rounded-lg mx-auto w-full max-md:pt-20 dark:bg-primary-900 dark:border-primary-900 ${
          messages.length > 0 ? "" : "h-full overflow-y-auto justify-center"
        }`}
      >
        {messages.length > 2 && !showHistory && (
          <QuestionAsked
            messages={messages}
            setQuestionAskedState={setQuestionAskedState}
            questionAskedState={questionAskedState}
          />
        )}
        {!showHistory ? (
          <>
            <div
              className={`w-full mx-auto custom-scrollbar transition-all duration-500  ${
                messages.length > 0
                  ? "flex-1 overflow-visible overflow-y-auto"
                  : "pb-8"
              } ${questionAskedState ? "md:pr-64" : ""}`}
            >
              <LogoNewChat
                hasMessages={messages.length > 0}
                handleNewChat={handleNewChat}
              />

              <div className="max-w-5xl mx-auto">
                <Greeting
                  hasMessages={messages.length > 0}
                  name={authState.idToken?.claims.name}
                />
                {messages.length > 0 && (
                  <div className="w-full space-y-5 mt-7 transition-all">
                    {messages.map((message, index) => (
                      <ChatMessage
                        key={index}
                        isAssistant={message.role === "assistant"}
                        content={message.content}
                        userName={authState.idToken?.claims.name}
                        timestamp={message.timestamp}
                        id={message.id}
                      />
                    ))}
                    {isSubmitting && <ResponseLoader />}
                    <div ref={messagesEndRef} />
                  </div>
                )}
              </div>
            </div>

            <div
              className={`flex-shrink-0 transition-all duration-500 ${
                questionAskedState ? "md:pr-64" : ""
              }`}
            >
              <div className="max-w-3xl mx-auto w-full py-4 md:pt-4">
                <PromptInput
                  value={inputValue}
                  onChange={setInputValue}
                  onSubmit={handleSubmit}
                  disabled={isSubmitting}
                  hasMessages={messages?.length > 0}
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  fileInputRef={fileInputRef}
                  llm={llm}
                  selectedModel={selectedModel}
                  setSelectedModel={setSelectedModel}
                  setIsModelDropdownOpen={setIsModelDropdownOpen}
                  isSubmitting={isSubmitting}
                  chatResponseType={chatResponseType}
                  setSelectedType={setSelectedType}
                  selectedType={selectedType}
                  setSettingsValues={setSettingsValues}
                />
              </div>
            </div>
          </>
        ) : (
          <AllHistory
            onChatClick={handleChatClick}
            onDeleteChat={handleDeleteTopic}
            currentTopicId={currentTopicId}
            showRecentChatConfirm={showRecentChatConfirm}
            handleNewChat={handleNewChat}
          />
        )}
      </div>
    </div>
  );
};

export default Chat;

const QuestionAsked = (props: any) => {
  return (
    <>
      <div className="group absolute top-4  right-6 z-20">
        <ChatBubbleBottomCenterTextIcon
          onClick={() => props.setQuestionAskedState(!props.questionAskedState)}
          className={`h-10 w-10 p-2 rounded-full  text-gray-500  cursor-pointer hover:bg-white hover:shadow-xl hover:text-primary-alt-600  overflow-hidden ${
            props.questionAskedState ? "" : ""
          }`}
        />
        <span
          className={`group-hover:opacity-100 group-hover:pointer-events-auto pointer-events-none text-nowrap opacity-0 transition-opacity bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute mx-auto ${
            props.isPinned
              ? "right-1/2 -translate-x-1/2 -top-8"
              : "right-full -translate-x-2 top-1/2 -translate-y-1/2"
          }`}
        >
          Show/hide all queries
        </span>
      </div>
      <div
        className={`max-md:shadow-2xl flex flex-col z-10  backdrop-blur-3xl bg-white bg-opacity-20 rounded-tl-3xl rounded-bl-3xl h-full absolute right-0 overflow-hidden transition-all duration-100  ${
          props.questionAskedState ? "md:w-64 w-3/4  " : "w-0 "
        }`}
      >
        <div
          className={`flex flex-col overflow-auto absolute left-0 right-0 bottom-0 top-12 transition-transform duration-100 px-4 pb-4 ${
            props.questionAskedState ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          {props.messages.map((item: any) => {
            return (
              <>
                {item.role == "user" && (
                  <a
                    className="py-2 hover:text-primary-alt-600 text-sm text-gray-500 border-b border-gray-200"
                    key={item.id}
                    href={`#${item.id}`}
                  >
                    {item.content}
                  </a>
                )}
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};
