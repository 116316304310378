import { useState, useEffect, useRef } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import appConfig from '../config/appConfig';

export interface Topic {
  id: string;
  title: string;
  created_at: string;
  updated_at: string;
  conversations: Array<{
    id: string;
    prompt: string;
    bot_response: string;
    created_at: string;
    citations?: Array<string>;
  }>;
}

export type ChatHistory = Topic[];

interface ApiResponse<T> {
  success: boolean;
  message: string;
  error: string | null;
  status_code: number;
  data: T | null;
}

export const useUserHistory = () => {
  const { authState } = useOktaAuth();
  const [history, setHistory] = useState<ChatHistory | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const hasFetched = useRef(false);

  const fetchUserHistory = async (userId: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const accessToken = authState?.accessToken?.accessToken;
      if (!accessToken) {
        throw new Error('No access token available');
      }

      const response = await fetch(appConfig.urls.chatHistory(userId), {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
      });

      const data: ApiResponse<ChatHistory> = await response.json();

      if (data.success && data.data) {
        const sortedHistory = [...data.data].sort((a, b) => {
          const dateA = new Date(a.updated_at).getTime();
          const dateB = new Date(b.updated_at).getTime();
          return dateB - dateA;
        });
        
        setHistory(sortedHistory);
        return sortedHistory;  
      } else {
        console.warn('Failed to fetch chat history:', data.error);
        setError(data.error || 'Failed to fetch chat history');
        return null;
      }
    } catch (err) {
      console.error('Error in fetchUserHistory:', err);
      setError(err instanceof Error ? err.message : 'Failed to fetch chat history');
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const refreshHistory = async () => {
    const uid = authState?.idToken?.claims.sub;
    if (uid) {
      return await fetchUserHistory(String(uid));  
    }
    return null;
  };

  useEffect(() => {
    if (hasFetched.current) {
      return;
    }

    if (authState?.isAuthenticated && authState.idToken?.claims.sub) {
      const uid = authState?.idToken?.claims.sub;
      
      if (!isLoading && !history) {
        hasFetched.current = true;
        fetchUserHistory(String(uid));
      }
    }
  }, [authState?.isAuthenticated, authState?.idToken?.claims.sub]);

  return {
    history,
    isLoading,
    error,
    refreshHistory
  };
};
