import React from "react";

export default function Greeting(props: any) {
  return (
    <>
      {!props.hasMessages && (
        <div className="mt-4 text-center">
          <p className="text-lg md:text-4xl text-primary-950 dark:text-gray-200 mb-0 ">
            Hi,{" "}
            <span className="font-bold text-primary-alt-600 dark:text-gray-200">
              {props.name}
            </span>
          </p>
        </div>
      )}
    </>
  );
}
