export const formatTimeAgo = (date: string | number) => {
  // Validate input
  if (date === undefined || date === null) {
    return "just now";
  }

  // Convert to number if it's a string
  const timestamp = typeof date === "string" ? parseInt(date, 10) : date;

  // Check for invalid timestamp
  if (isNaN(timestamp)) {
    console.warn("Invalid timestamp:", date);
    return "just now";
  }

  const now = new Date();
  const past = new Date(timestamp);

  // Validate date object
  if (isNaN(past.getTime())) {
    console.warn("Invalid date:", past);
    return "just now";
  }

  const diffInSeconds = Math.floor((now.getTime() - past.getTime()) / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);
  const diffInMonths = Math.floor(diffInDays / 30);
  const diffInYears = Math.floor(diffInDays / 365);

  if (diffInYears > 0) return `${diffInYears}y ago`;
  if (diffInMonths > 0) return `${diffInMonths}mo ago`;
  if (diffInDays > 0) return `${diffInDays}d ago`;
  if (diffInHours > 0) return `${diffInHours}h ago`;
  if (diffInMinutes > 0) return `${diffInMinutes}m ago`;
  return "just now";
};
